import './App.css';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function App() {
  // const [isHoveredFirstLevel, setIsHoveredFirstLevel] = useState(false);
  // const [isHoveredSecondLevel, setIsHoveredSecondLevel] = useState(false);
  // const [isHoveredThirdLevel, setIsHoveredThirdLevel] = useState(false);

  // const handleMouseEnterFirstLevel = () => {
  //   setIsHoveredFirstLevel(true);
  // };

  // const handleMouseLeaveFirstLevel = () => {
  //   setIsHoveredFirstLevel(false);
  // };

  // const handleMouseEnterSecondLevel = () => {
  //   setIsHoveredSecondLevel(true);
  // };

  // const handleMouseLeaveSecondLevel = () => {
  //   setIsHoveredSecondLevel(false);
  // };

  // const handleMouseEnterThirdLevel = () => {
  //   setIsHoveredThirdLevel(true);
  // };

  // const handleMouseLeaveThirdLevel = () => {
  //   setIsHoveredThirdLevel(false);
  // };

  function searchSubmission(event) {
    event.preventDefault();
    const searchValue = document.getElementById('user-search').value;
    const url = `./user_search.html?inputSearch=${encodeURIComponent(searchValue)}`;
    window.location.href = url;
    return false;
  }

//Dynamically fetching of Funding Opportunities info
const [renderedYouthOpportunities, setRenderedYouthOpportunities] = useState([]);
useEffect(() => {
    fetch('https://fundsforstartups.org/backend/youth_opportunities.php')
      .then(res => res.json())
      .then(data => {
        data = Object.values(data);
        const youthInfo = data.map(item =>(
          <div key={item.id}>
             <a href={`./content_page.html?${encodeURIComponent(`${item.category}`.toLowerCase().replace(/\s+/g, '-'))}/${encodeURIComponent(`${item.heading}`.toLowerCase().replace(/\s+/g, '-'))}`}> 
            <img  src={item.pic} alt="Funds for startups" />
            </a>
            <div className="card-title">
               <a href={`./content_page.html?${encodeURIComponent(`${item.category}`.toLowerCase().replace(/\s+/g, '-'))}/${encodeURIComponent(`${item.heading}`.toLowerCase().replace(/\s+/g, '-'))}`}>
                {item.heading}
              </a>
            </div>
            <div className="deadline">Deadline: {item.deadline}</div>
            <div className="card-body">{item.content}</div>
            <div className='read-more-container'>
            <a href={`./content_page.html?${encodeURIComponent(`${item.category}`.toLowerCase().replace(/\s+/g, '-'))}/${encodeURIComponent(`${item.heading}`.toLowerCase().replace(/\s+/g, '-'))}`}
             className='read-more'>
              Read more <FontAwesomeIcon icon={faArrowRight} className='read-more-fa'/>
             </a>
             </div>
          </div>
        ));
         setRenderedYouthOpportunities(youthInfo);
      })
      .catch(error => {
        console.error('Error fetching youth opportunities:', error);
      });
  }, []);

//Dynamically fetching of Training and Fellowship info
const [renderedResearchFeasibility, setRenderedResearchFeasibility] = useState([]);
useEffect(() => {
    fetch('https://fundsforstartups.org/backend/research_feasibility.php')
      .then(res => res.json())
      .then(data => {
        data = Object.values(data);
        const reasearchInfo = data.map(item =>(
          <div key={item.id}>
           <a href={`./content_page.html?${encodeURIComponent(`${item.category}`.toLowerCase().replace(/\s+/g, '-'))}/${encodeURIComponent(`${item.heading}`.toLowerCase().replace(/\s+/g, '-'))}`}>
            <img  src={item.pic} alt={item.heading} />
            </a>
            <div className="card-title">
               <a href={`./content_page.html?${encodeURIComponent(`${item.category}`.toLowerCase().replace(/\s+/g, '-'))}/${encodeURIComponent(`${item.heading}`.toLowerCase().replace(/\s+/g, '-'))}`}>
                {item.heading}
              </a>
            </div>
            <div className="deadline">Deadline: {item.deadline}</div>
            <div className="card-body">{item.content}</div>
            <div className='read-more-container'>
            <a href={`./content_page.html?${encodeURIComponent(`${item.category}`.toLowerCase().replace(/\s+/g, '-'))}/${encodeURIComponent(`${item.heading}`.toLowerCase().replace(/\s+/g, '-'))}`}
             className='read-more'>
              Read more <FontAwesomeIcon icon={faArrowRight} className='read-more-fa'/>
             </a>
             </div>
          </div>
        ));
        setRenderedResearchFeasibility(reasearchInfo);
      })
      .catch(error => {
        console.error('Error fetching youth opportunities:', error);
      });
  }, []);

  //Toggler function
  function toggleBtn(){
     //var classList = document.getElementById('toggleBtn');
    var navbarLinks = document.getElementById('navbarLinks');
    if (navbarLinks.style.display === 'block') {
        navbarLinks.style.display = 'none';
    } else {
        navbarLinks.style.display = 'block';
    }
  }

  return (
    <div className='main-app'>
    <div className="main-container">
        <nav className="main-navbar">
            <a href="/"><img src="funds4startups.png" alt="Funds for startups"></img></a>
            <button onClick={toggleBtn} id="toggleBtn" aria-label="Toggle Navigation">☰</button>
            <ul id='navbarLinks'>  
            <li><a href="./about_us.html">About Us</a></li>
                <li><a href="./funding_opportunity.html">Funding Opportunities</a></li>
                <li><a href="./training_fellowship.html">Training & Fellowship</a></li>
                <li><a href="./esos.html">ESOs</a></li>
            </ul>
        </nav>
                <div className="nav-banner">
                    <h2>Repository For Business Funding Opportunities Across The Globe</h2>
                </div>
        <form className="search-form" onSubmit={searchSubmission}>
            <input type="search" id='user-search' placeholder="Search by Sector e.g health, or Bloc e.g North America..."></input>
            <input type="submit" value="Search"></input>
        </form>
           
           <div className="main-section">
            <img src="side2.jpg" alt='Funding for startups'></img>
            <div className="sub-section">
                <button className="new-book">CONNECT WITH US FOR</button>
                <h1>MENTORSHIP, COACHING AND NETWORKING</h1>
                <p className="first-paragraph">Discover flexible ways to connect with us, tailored for your convinience.</p>
                <p className="second-paragraph">Both online and physical presence options</p>
                <a href="./connect_us.html" className="get-now">CONNECT NOW</a>
            </div>
        </div>
        <div className="cards-container">
            <div className="card-child1">
                <h3>Recent Calls</h3>
                <div className="card">
                {renderedYouthOpportunities}
                </div>
            </div>
            <div className="card-child">
                <h3>Previous Calls</h3>
             <div className="card">
              {renderedResearchFeasibility}
            </div>
            </div>
        </div>
        <footer className="footer-main">
                <ul>
                    <li><a href="./contact_us.html">Contact Us</a></li>
                    <li><a href="./connect_us.html">Connect With Us</a></li>
                    <li><a href="./about_us.html">About Us</a></li>
                </ul>
                <ul>
                    <li><a href="./guest_post.html">Add Guest Post</a></li>
                    <li><a href="./eso_application.html">Enlist Your ESO</a></li>
                    <li><a href="./funding_opportunity.html">Funding Opportunities</a></li>
                    <li><a href="./training_fellowship.html">Training & Fellowship</a></li>
                </ul>
                <ul>
                    {/* <li><a href="#">Funds4StartUps LLC</a></li>
                    <li><a href="#">1500 East Lake Street</a></li>
                    <li><a href="#">Minneapolis, Minnesota</a></li>
                    <li><a href="#">United States</a></li> */}
                </ul>
            </footer>
           </div>
    </div>
  );
}

export default App;
